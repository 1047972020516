
export default {
  inheritAttrs: false,

  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    colorNameLight() {
      return this.messages?.length ? "--color-yellow-50" : "--color-transparent";
    },

    colorNameHeavy() {
      return this.messages?.length ? "--color-yellow-900" : "--color-black-900";
    },
  },
};
